<template>
  <div>
    <div class="new-header header-dividing-border bc3">
      <div>
        <div class="game_serch carousel-live-feed-holder">
          <div class="live-feed-secondary-part-holder">
            <table>
              <thead>
                <tr>
                  <th class="text-center">{{ $t("text_fund_name") }}</th>
                  <th class="text-center">{{ $t("text_fund_price") }}</th>
                  <th class="text-center">{{ $t("text_fund_amount") }}</th>
                  <th class="text-center">{{ $t("text_fund_arr") }}</th>
                  <th class="text-center">
                    <span style="visibility: hidden">1234567890</span
                    >{{ $t("text_stock_action")
                    }}<span style="visibility: hidden">1234567890</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="l in etf_list" :key="l">
                  <td class="text-center">{{ l.cname }}</td>
                  <td class="text-center">
                    <img
                      src="@/assets/img/main/diamond.png"
                      style="width: 25px; height: 25px"
                      class="me-1"
                    />
                    <span>{{ l.payment }}</span>
                  </td>
                  <td class="text-center">{{ l.unit }}</td>
                  <td class="text-center">{{ l.rate }}</td>
                  <td class="text-center d-flex" style="gap: 5px">
                    <button
                      class="border-0 text-white bg-s-buy p-2 w-50"
                      @click="click_detail('buy', l)"
                    >
                      <span>{{ $t("invest_text6") }}</span>
                    </button>
                    <button
                      class="border-0 text-white bg-s-sell p-2 w-50"
                      @click="click_detail('sell', l)"
                    >
                      <span>{{ $t("text_sell") }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <finance
      v-if="flag_finance_page"
      :click_list="click_list"
      :action="action"
      @fun_flag_page="fun_flag_page"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import finance from "./finance.vue";
export default {
  computed: {},
  data() {
    return {
      etf_list: [],
      flag_finance_page: false,
    };
  },
  components: {
    finance,
  },
  methods: {
    etf_holding: function () {
      let param = {
        action: "etf_holding",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.etf_list = res.data;
        }
      });
    },
    click_detail: function (action, e) {
      this.action = action;
      this.click_list = e;
      this.flag_finance_page = true;
    },
    fun_flag_page: function (e) {
      this.flag_finance_page = e;
    },
  },
  watch: {},
  created() {
    this.etf_holding();
  },
};
</script>
