<template>
  <div class="wc-PageView wc_message wc_bonuscenter wc_bonuscenter1">
    <div class="wc-PageView_ContentContainer wc-ModulePage_ContentContainer wc_ContentContainer1">
      <div class="message_second" style="padding: 0 20px">
        <div>
          <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
            <div class="nh-NavigationHeaderModule_Title">
              {{ $t("text_assets_center") }}
            </div>
          </div>
        </div>
        <div>
          <!-- <div class="sticky-header ms-0">
            <div class="sticky-header-inner">
              <div class="nav-wrapper">
                <ul class="nav">
                  <li
                    class="nav-item"
                    :class="this.type_id == 1 ? 'nav-item-selected first' : ''"
                    @click="this.type_id = 1"
                  >
                    <div>
                      <span>{{ $t("text_assetscenter1") }}</span>
                    </div>
                  </li>

                  <li
                    class="nav-item last"
                    :class="this.type_id == 2 ? 'nav-item-selected first' : ''"
                    @click="this.type_id = 2"
                  >
                    <div>
                      <span>{{ $t("text_assetscenter2") }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->

          <center1 v-if="this.type_id == 1" />
          <center2 v-if="this.type_id == 2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Cookies from "js-cookie";
import center1 from "./assets_center_1.vue";
import center2 from "./assets_center_2.vue";

export default {
  computed: {},
  data() {
    return {
      type_id: 1,
    };
  },
  components: { center1, center2 },
  methods: {},
  watch: {},
  created() {},
};
</script>
