export const main_casino_on = {
  en: require("@/assets/img/main/button_casino_on_en.png"),
  cn: require("@/assets/img/main/button_casino_on.png"),
};
export const main_sport_on = {
  en: require("@/assets/img/main/button_sports_on_en.png"),
  cn: require("@/assets/img/main/button_sports_on.png"),
};
export const main_casino_off = {
  en: require("@/assets/img/main/button_casino_off_en.png"),
  cn: require("@/assets/img/main/button_casino_off.png"),
};
export const main_sport_off = {
  en: require("@/assets/img/main/button_sports_off_en.png"),
  cn: require("@/assets/img/main/button_sports_off.png"),
};
export const main_logo = {
  en: require("@/assets/logo.png"),
  cn: require("@/assets/logo_ch_b.png"),
};
export const main_logo_w = {
  en: require("@/assets/logo_w.png"),
  cn: require("@/assets/logo_ch_w.png"),
};
