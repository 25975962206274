<template>
  <div>
    <!-- echarts图表绘制在这里 -->
    <div
      ref="canvas"
      style="width: 100%; height: 500px"
      class="position-relative"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LineGraph",
  props: { tableData: Array },
  data() {
    return {};
  },
  mounted() {
    // 根据准备好的dom初始化echarts实例
    // var myChart = echarts.init(document.getElementById("canvas"));
    var myChart = echarts.init(this.$refs.canvas);

    // 使用刚才指定的配置项和数据显示图表
    myChart.setOption({
      // tooltip: {
      //   trigger: "axis",
      //   // axisPointer: {
      //   //   type: "cross",
      //   //   label: {
      //   //     backgroundColor: "#6a7985",
      //   //   },
      //   // },
      // },
      grid: {
        //占滿
        top: 30,
        bottom: 0,
        left: 20,
        right: 30,
        containLabel: true,
      },
      xAxis: {
        type: "category",
        name: "",
        show: true,
        data: [
          "7/1",
          "7/2",
          "7/3",
          "7/4",
          "7/5",
          "7/6",
          "7/7",
          "7/8",
          "7/9",
          "7/10",
          "7/11",
          "7/12",
          "7/13",
          "7/14",
          "7/15",
        ],
        boundaryGap: false,
        axisLabel: {
          // show: true,
          // margin: 20,
          color: "#000",
          // fontSize: "20",
          // align: "left",
        },
        axisLine: {
          lineStyle: {
            color: "#ccc",
          },
        },
      },
      yAxis: {
        type: "value",
        // show: false,
        boundaryGap: ["1", "1"],
        min: Math.min.apply(0, this.tableData) - 30,
        max: Math.max.apply(0, this.tableData) + 30,
        position: "right",
        axisLabel: {
          color: "#08062a",
        },
        axisLine: {
          lineStyle: {
            color: "#ccc",
          },
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          // name: "降水量",
          data: this.tableData,
          // data: [
          //   ["2024-7-1", 450],
          //   ["2024-7-2", 350],
          //   ["2024-7-3", 290],
          //   ["2024-7-4", 380],
          //   ["2024-7-5", 540],
          //   ["2024-7-6", null],
          //   ["2024-7-7", null],
          // ],
          type: "line",
          // smooth: true,
          // symbol: "none",
          // showSymbol:true,
          label: {
            show: true,
          },
          ConnectNulls: true,
          lineStyle: {
            color: this.change_color(),
          },
          itemStyle: {
            normal: {
              color: "#000",
            },
          },
          // areaStyle: {
          //   color: {
          //     type: "linear",
          //     x: 0, //右
          //     y: 0, //下
          //     x2: 0, //左
          //     y2: 1, //上
          //     colorStops: [
          //       {
          //         offset: 0,
          //         color: "green",
          //       },
          //       {
          //         offset: 1,
          //         color: "green",
          //       },
          //     ],
          //   },
          // },
        },
      ],
    });
  },

  methods: {
    change_color: function () {
      if (this.tableData[0] > this.tableData[this.tableData.length - 1])
        return "green";
      else if (this.tableData[0] < this.tableData[this.tableData.length - 1])
        return "red";
      else return "black";
    },
  },
};
</script>
