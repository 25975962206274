<template>
  <div class="wc-PageView wc_message wc_bonuscenter">
    <div class="wc-PageView_ContentContainer wc-ModulePage_ContentContainer">
      <div class="message_second" style="padding: 0 20px">
        <div>
          <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
            <div class="nh-NavigationHeaderModule_Title">
              {{ $t("text_bonuscenter") }}
            </div>
          </div>
        </div>
        <div>
          <div class="sticky-header">
            <div class="sticky-header-inner">
              <div class="nav-wrapper">
                <ul class="nav">
                  <li
                    class="nav-item"
                    :class="this.type_id == 1 ? 'nav-item-selected first' : ''"
                    @click="this.type_id = 1"
                  >
                    <div>
                      <span>{{ $t("text_bonuscenter1") }}</span>
                    </div>
                  </li>

                  <li
                    class="nav-item"
                    :class="this.type_id == 2 ? 'nav-item-selected first' : ''"
                    @click="this.type_id = 2"
                  >
                    <div>
                      <a>{{ $t("text_bonuscenter2") }}</a>
                    </div>
                  </li>

                  <li
                    class="nav-item"
                    :class="this.type_id == 3 ? 'nav-item-selected first' : ''"
                    @click="this.type_id = 3"
                  >
                    <div>
                      <a>{{ $t("text_game_share") }}</a>
                    </div>
                  </li>

                  <li
                    class="nav-item last"
                    :class="this.type_id == 4 ? 'nav-item-selected first' : ''"
                    @click="this.type_id = 4"
                  >
                    <div>
                      <a>{{ $t("text_bonuscenter3") }}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <center1 v-if="this.type_id == 1" />
          <center2 v-if="this.type_id == 2" />
          <center3 v-if="this.type_id == 3" />
          <center4 v-if="this.type_id == 4" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Cookies from "js-cookie";
import center1 from "./bonus_center_1.vue";
import center2 from "./bonus_center_2.vue";
import center3 from "./bonus_center_3.vue";
import center4 from "./bonus_center_4.vue";

export default {
  computed: {},
  data() {
    return {
      type_id: 1,
    };
  },
  components: { center1, center2, center3, center4 },
  methods: {},
  watch: {},
  created() {},
};
</script>
