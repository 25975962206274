<template>
  <div class="new-header header-dividing-border">
    <div>
      <div class="game_serch">
        <div
          class="game_card d-flex align-items-start"
          v-for="l in 10"
          :key="l"
        >
          <div style="width: 20%" class="me-3 my-auto">
            <img
              :src="require('@/assets/img/game/all/slot_jili.png')"
              class="w-100"
            />
          </div>
          <div class="w-25 me-3">
            <p>PRIVATE FUND NAME</p>
            <p style="word-break: break-word;">123123123123123123123123</p>
          </div>
          <div class="w-25 me-3 text-center">
            <p>ANNUAL PROFIT RETURN RATE</p>
            <h3 class="fw-bolder">1.6%</h3>
          </div>
          <div class="w-25 me-3">
            <p class="text-center">UNIT PRICE</p>
            <p>
              <img
                src="@/assets/img/main/diamond.png"
                style="width: 25px; height: 25px"
                class="me-1"
              />
              123
            </p>
            <button
              class="border-0 text-white bg_green_btn p-2 w-100 bg-orange"
            >
              <span>Purchase</span>
            </button>
          </div>
          <div style="width: 15%" class="text-center">
            <p>MY SHARE</p>
            <p>123</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {},
};
</script>
