<template>
  <div>
    <div class="finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="this.$emit('fun_flag_page', false)">
          ×
        </div>
        <div
          class="finance_title"
          :class="action.toLowerCase() == 'sell' ? 'bg-red' : ''"
        >
          {{ $t("invest_text1") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <p class="mb-1">{{ $t("invest_text2") }}：{{ cou_name }}</p>
            <p style="word-wrap: break-word">{{ click_list.description }}</p>
            <p>
              {{ $t("invest_text3") }}：
              <img
                src="@/assets/img/main/diamond.png"
                style="width: 25px; height: 25px"
                class="me-1"
              />{{ cou_price }}
            </p>
            <!-- <p>
            {{ $t("invest_text4") }}：
            {{ parseFloat((cou_rate * 100).toFixed(2)) }}%
          </p> -->

            <div class="mb-2">
              <p class="mb-2">
                {{
                  action.toLowerCase() == "sell"
                    ? $t("text_sell_amount")
                    : $t("invest_text15")
                }}
              </p>

              <div
                class="deposit_amount w-100 d-flex finance_text align-items-center"
              >
                <img
                  src="@/assets/img/main/img_stock.png"
                  style="width: 25px; height: 25px"
                  class="mx-2"
                />
                <input type="text" v-model="amount" class="text-center" />
                <span class="deposit_cur">
                  {{ $t("text_share") }}
                </span>
              </div>

              <p v-show="flag_amount_error">
                <span style="color: Red">{{ $t("error_tips") }}</span>
              </p>
            </div>

            <div>
              <p class="mb-2">
                {{
                  action.toLowerCase() == "sell"
                    ? $t("text_sell_price")
                    : $t("invest_text5")
                }}
              </p>
              <div
                class="deposit_amount w-100 d-flex finance_text align-items-center"
              >
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                  class="mx-2"
                />
                <input type="text" v-model="payment" class="text-center" />
                <span class="deposit_cur">
                  {{ $t("cur") }}
                </span>
              </div>
              <p v-show="flag_pay_error">
                <span style="color: Red">{{ $t("error_tips") }}</span>
              </p>
            </div>
            <div class="text-center mt-3">
              <p v-if="action.toLowerCase() != 'sell'" style="color: #7f7f7f">
                {{ $t("text_tran_fee") }}：{{ click_list.fee * 100 }}%
              </p>
            </div>
            <div class="col-10 text-white mx-auto my-3">
              <button
                @click="buy_sharebonus_item"
                class="border-0 text-white p-2 w-100"
                :disabled="flag_pay_error"
                :style="
                  flag_pay_error
                    ? 'cursor: no-drop;background-color: #CCC;'
                    : ''
                "
                :class="
                  action.toLowerCase() == 'sell'
                    ? 'bg_red_btn'
                    : 'bg_light_green_btn'
                "
              >
                <span>{{
                  action.toLowerCase() == "sell"
                    ? $t("text_sell")
                    : $t("invest_text6")
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <withdraw_pw
      v-if="flag_pw_page"
      :click_list="click_list"
      :payment="payment"
      :action="action"
      :amount="amount"
      :fina_inve="'stock'"
      @fun_pw_page="fun_pw_page"
    />
  </div>
</template>
<script>
import withdraw_pw from "./withdraw_pw.vue";
export default {
  props: { click_list: Array, action: String },
  data() {
    return {
      flag_amount_error: false,
      flag_pay_error: false,
      amount: 0,
      payment: 0,
      cou_name: this.click_list.cname,
      cou_price: this.click_list.payment,
      cou_rate: this.click_list.rate,
      cou_mpkidx: this.click_list.mpkidx,
      flag_pw_page: false,
    };
  },
  components: { withdraw_pw },
  methods: {
    buy_sharebonus_item: function () {
      if (this.amount != 0 && this.amount != "" && this.amount != null) {
        if (this.payment != 0 && this.payment != "" && this.payment != null) {
          this.flag_pw_page = true;
        } else this.flag_pay_error = true;
      } else this.flag_amount_error = true;
    },
    fun_pw_page: function (e, e1) {
      this.flag_pw_page = e;
      if (e1 != null && e1 != "") this.$emit("fun_flag_page", false);
    },
  },
  mounted() {},
  created() {
    // this.sharebonus_item();
  },
  watch: {
    amount: function (e) {
      if (!Number(e)) {
        this.flag_amount_error = true;
      } else this.flag_amount_error = false;
    },
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
    },
  },
};
</script>
