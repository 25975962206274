<template>
  <!-- 侧边的nav -->
  <div
    class="collapse navbar-collapse pe-xl-3 ps-xl-4"
    id="navbarNav"
    :class="flag_nav ? 'show' : 'd-none'"
  >
    <div class="nav_left_show">
      <img
        :src="img_logo"
        class="right-menu-logo"
        alt="Logo"
        style="visibility: hidden"
      />
      <div class="icon-wrapper" @click="hide_nav()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#8d999f"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </div>
    </div>
    <!-- 未登入 -->
    <div class="userMenu-mob" v-if="!flag_code">
      <div class="mobile-menu-containers first">
        <div>
          <div class="mobile-menu-containers-main-titles">
            <!-- <h3>{{ $t("text_wel") }}</h3> -->
            <img src="@/assets/richance_logo_b.png" />
          </div>
        </div>
        <div class="spbtw">
          <button
            class="transparent-button transparent-button-active"
            @click="
              open_login('islogin');
              false_nav();
            "
          >
            {{ $t("login1") }}</button
          ><button
            class="transparent-button transparent-button-active"
            @click="
              open_login('isreg');
              false_nav();
            "
          >
            {{ $t("text_join") }}
          </button>
        </div>
      </div>

      <maintop1sub />
    </div>
    <!-- 登入 -->
    <div class="userMenu-mob userMenu-mob-in" v-else>
      <div class="mobile-menu-containers">
        <div class="mobile-menu-profile-container">
          <div class="mobile-menu-profileDetails">
            <span>{{ uid }}<span class="mobile-menu-vip-label"></span></span>
            <div class="user-id-wrapper">
              <span>ID: {{ sharecode }}</span
              >&nbsp;
              <svg
                @click="Copy('url')"
                width="19"
                height="24"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6429 21.5V23.375C17.6429 23.9963 17.0672 24.5 16.3571 24.5H1.78571C1.07563 24.5 0.5 23.9963 0.5 23.375V6.125C0.5 5.50367 1.07563 5 1.78571 5H5.64286V18.875C5.64286 20.3225 6.98863 21.5 8.64286 21.5H17.6429ZM17.6429 5.375V0.5H8.64286C7.93277 0.5 7.35714 1.00367 7.35714 1.625V18.875C7.35714 19.4963 7.93277 20 8.64286 20H23.2143C23.9244 20 24.5 19.4963 24.5 18.875V6.5H18.9286C18.2214 6.5 17.6429 5.99375 17.6429 5.375ZM24.1234 3.92052L20.5908 0.829484C20.3497 0.61852 20.0227 0.500002 19.6817 0.5H19.3571V5H24.5V4.71598C24.5 4.41763 24.3645 4.13149 24.1234 3.92052Z"
                  fill="#00af4c"
                ></path>
              </svg>
            </div>
          </div>
          <!-- <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi4xNDMiIGhlaWdodD0iMjAuODkyIiB2aWV3Qm94PSIwIDAgMTIuMTQzIDIwLjg5MiI+CiAgPHBhdGggaWQ9Im1lbnVfaWNvbl9hcnJvd25leHQiIGQ9Ik0xOS4yLDBsLTguNzUsOC43NUwxLjcsMCwwLDEuNywxMC40NDUsMTIuMTQzLDIwLjg5MiwxLjdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwLjg5Mikgcm90YXRlKC05MCkiIGZpbGw9IiM4Mzk0QTAiLz4KPC9zdmc+Cg=="
            class="arrow-svg"
            alt="arrow-svg"
          /> -->
        </div>
      </div>
      <div class="mobile-menu-containers">
        <div class="mobile-menu-profile-container">
          <div class="mobile-menu-profileDetails">
            <span>{{ $t("cur") }} {{ balance }} </span
            ><span>{{ $t("text_total_bal") }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="mobile-menu-containers first">
        <div class="mobile-menu-buttons">
          <button
            class="transparent-button transparent-button-active"
            @click="
              $router.push({ path: '/ME/wallet', query: { id: 'withdraw' } });
              false_nav();
            "
          >
            {{ $t("withdrawl") }}</button
          ><button
            class="transparent-button transparent-button-active mobile-menu-deposit"
            @click="
              $router.push({ path: '/ME/wallet', query: { id: 'deposit' } });
              false_nav();
            "
          >
            {{ $t("deposit") }}
          </button>
        </div>
      </div> -->
      <maintop1sub :subid="'logined'" />
      <div class="logout-button">
        <div v-if="flag_code" @click="logout">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5675 10.1925L6.625 11.25L10.375 7.5L6.625 3.75L5.5675 4.8075L7.5025 6.75H0.25V8.25H7.5025L5.5675 10.1925ZM12.25 0.75H1.75C0.9175 0.75 0.25 1.425 0.25 2.25V5.25H1.75V2.25H12.25V12.75H1.75V9.75H0.25V12.75C0.25 13.575 0.9175 14.25 1.75 14.25H12.25C13.075 14.25 13.75 13.575 13.75 12.75V2.25C13.75 1.425 13.075 0.75 12.25 0.75Z"
              fill="#FFF"
              fill-opacity="0.9"
            ></path>
          </svg>
          <span>{{ $t("logout") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
import maintop1sub from "./Main_top1_sub.vue";
import { main_logo } from "@/components/Img/img";

export default {
  name: "maintop1",
  data() {
    return {
      code: Cookies.get("code"),
      flag_draw: false,
      flag_extra: false,
      uid: localStorage.getItem("uid"),
      shareurl: "",
      sharecode: "",
      img_logo:
        localStorage.getItem("locale") == null ||
        localStorage.getItem("locale") == "" ||
        (localStorage.getItem("locale") != "en" &&
          localStorage.getItem("locale") != "cn")
          ? JSON.parse(JSON.stringify(main_logo))["en"]
          : JSON.parse(JSON.stringify(main_logo))[
              localStorage.getItem("locale")
            ],
    };
  },
  components: { maintop1sub },
  computed: {
    ...mapGetters(["flag_nav", "balance", "flag_code"]),
  },
  methods: {
    ...mapActions(["getBalance"]),
    logout: function () {
      Cookies.remove("code");
      localStorage.removeItem("uid");
      localStorage.removeItem("utype");
      this.$router.push("/");
      window.location.reload();
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    false_nav: function () {
      this.$store.commit("flagnav", false);
    },
    open_login: function (id) {
      this.$store.commit(id, true);
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.shareurl = res["url"];
          this.sharecode = res["ref"];
        }
      });
    },
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
  },
  created() {
    if (this.code != "") {
      this.getBalance();
      this.share();
    }
  },
  watch: {},
};
</script>
