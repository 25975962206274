<template>
  <div>
    <div class="signup-popup-container">
      <span class="popup-close-btn-signup" @click="close_reg()"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#8d999f"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </span>
      <div>
        <div class="signup-desk">
          <div class="sign-up-container">
            <img :src="img_logo" class="sign-up-container__logo" alt="Logo" />
            <div class="sign-up-container__message"></div>
            <div class="sign-up-container__form">
              <form method="POST" @submit="Register">
                <div class="single-form-item reg_cScreenname">
                  <label>{{ $t("text_userid") }}</label
                  ><input
                    type="text"
                    :placeholder="$t('text_et')"
                    v-model="uid"
                    maxlength="10"
                    minlength="4"
                    required="required"
                  />
                </div>
                <div class="single-form-item reg_password">
                  <label>{{ $t("passwordTitle") }}</label
                  ><input
                    :type="flag_eye ? 'text' : 'password'"
                    :placeholder="$t('text_et')"
                    v-model="pwd"
                    minlength="6"
                    maxlength="12"
                    required="required"
                  />
                  <span class="validating-icon" @click="flag_eye = !flag_eye">
                    <img
                      :src="
                        flag_eye
                          ? require('@/assets/img/main/open_eye.png')
                          : require('@/assets/img/main/close_eye.png')
                      "
                    />
                  </span>
                </div>
                <div class="single-form-item reg_password">
                  <label>{{ $t("comfpwd") }}</label
                  ><input
                    :type="flag_eye1 ? 'text' : 'password'"
                    :placeholder="$t('text_et')"
                    v-model="pwd1"
                    minlength="6"
                    maxlength="12"
                    required="required"
                  />
                  <span class="validating-icon" @click="flag_eye1 = !flag_eye1">
                    <img
                      :src="
                        flag_eye1
                          ? require('@/assets/img/main/open_eye.png')
                          : require('@/assets/img/main/close_eye.png')
                      "
                    />
                  </span>
                </div>
                <div class="single-form-item reg_password">
                  <label>{{ $t("text_input_pw") }}</label
                  ><input
                    :type="flag_eye2 ? 'text' : 'password'"
                    :placeholder="$t('text_et')"
                    v-model="tradepw"
                    minlength="4"
                    maxlength="4"
                    required="required"
                  />
                  <span class="validating-icon" @click="flag_eye2 = !flag_eye2">
                    <img
                      :src="
                        flag_eye2
                          ? require('@/assets/img/main/open_eye.png')
                          : require('@/assets/img/main/close_eye.png')
                      "
                    />
                  </span>
                </div>
                <div
                  class="single-form-item reg_cEmail"
                  v-if="set_regi.have_email"
                >
                  <label>{{ $t("email") }}</label
                  ><input
                    type="email"
                    :placeholder="$t('text_et')"
                    v-model="email"
                    maxlength="50"
                    required="required"
                  />
                </div>
                <div
                  class="single-form-item reg_cEmail"
                  v-if="set_regi.have_name"
                >
                  <label>{{ $t("text_realNameTitle") }}</label
                  ><input
                    type="text"
                    :placeholder="$t('text_et')"
                    v-model="cname"
                    maxlength="50"
                    required="required"
                  />
                </div>
                <div
                  class="single-form-item reg_cEmail"
                  v-if="set_regi.have_phone"
                >
                  <label>{{ $t("reg_phone") }}</label
                  ><input
                    type="text"
                    :placeholder="$t('text_et')"
                    v-model="phone"
                    maxlength="50"
                    required="required"
                  />
                </div>
                <div
                  class="single-form-item reg_cEmail"
                  v-if="set_regi.have_birthday"
                >
                  <label>{{ $t("text_birth") }}</label
                  ><input
                    type="text"
                    :placeholder="$t('text_et')"
                    v-model="birthday"
                    maxlength="50"
                    required="required"
                  />
                </div>
                <div class="affiliate-code-input-container">
                  <button type="button" @click="flag_ref = !flag_ref">
                    {{ $t("referenceCodeTitle")
                    }}<span :class="flag_ref ? 'expanded' : ''">{{
                      flag_ref ? "-" : "+"
                    }}</span>
                  </button>
                  <div
                    class="single-form-item reg_affiliateCode"
                    v-if="flag_ref"
                  >
                    <label>{{ $t("referenceCodeTitle") }}</label
                    ><input
                      type="text"
                      class="invalid"
                      :placeholder="$t('referenceCodeTitle')"
                      v-model="refcode"
                      :disabled="dis_refcode"
                    />
                  </div>
                </div>
                <div class="single-form-item reg_cEmail">
                  <label>{{ $t("text_Captcha") }}</label
                  ><input
                    type="text"
                    :placeholder="$t('text_et')"
                    v-model="captcha"
                    maxlength="4"
                    required="required"
                    ref="captcha"
                  />
                  <captchaview ref="captgetcode" />
                </div>
                <div class="reg_agree">
                  <div class="valid">
                    <span>{{ $t("text_agree_term") }}</span>
                  </div>
                </div>
                <div class="btn-holder">
                  <!-- <button type="submit" id="regSubmit1">
                    {{ $t("text_join") }}
                  </button> -->
                  <input
                    type="submit"
                    id="regSubmit1"
                    :value="$t('text_join')"
                  />
                </div>
              </form>
            </div>
            <div class="sign-up-container__login-message">
              {{ $t("already")
              }}<span
                @click="
                  close_reg();
                  open_login('islogin');
                "
                >{{ $t("login1") }}</span
              >
            </div>
            <!-- <div class="sign-up-container__terms">条款和条件</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import api from "../components/http/api";
import captchaview from "./Home/captcha.vue";
import { main_logo_w } from "@/components/Img/img";

export default {
  name: "register",
  data() {
    return {
      set_regi: "",
      uid: "",
      pwd: "",
      pwd1: "",
      tradepw: "",
      cname: "",
      phone: "",
      birthday: "",
      email: "",
      refcode: localStorage.getItem("introducer") ?? "",
      dis_refcode:
        localStorage.getItem("introducer") != null &&
        localStorage.getItem("introducer") != ""
          ? true
          : false,
      list: [
        "@gmail.com",
        "@hotmail.com",
        "@outlook.com",
        "@live.com",
        "@yahoo.com",
        "@icloud.com",
        "@facebook.com",
        "@me.com",
      ],
      emil_index: null,
      flag_ref: false,
      flag_eye: false,
      flag_eye1: false,
      img_logo:
        localStorage.getItem("locale") == null ||
        localStorage.getItem("locale") == "" ||
        (localStorage.getItem("locale") != "en" &&
          localStorage.getItem("locale") != "cn")
          ? JSON.parse(JSON.stringify(main_logo_w))["en"]
          : JSON.parse(JSON.stringify(main_logo_w))[
              localStorage.getItem("locale")
            ],
      flag_eye2: false,
    };
  },
  async created() {
    await this.GetIP();
    if (localStorage.getItem("ip") != null) {
      if (localStorage.getItem("ip").length < 7) {
        this.$MSG.API_popmsg(
          "IP is error, Please try again or change another network",
          "",
          "error"
        );
      }
    }
    this.Set_Register();
  },
  methods: {
    async GetIP() {
      await api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    Set_Register: function () {
      let param = {
        action: "Set_Register",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.set_regi = res;
      });
    },
    Register: function (e) {
      e.preventDefault();
      var re = /^[a-zA-Z0-9]+$/;
      var re_trade_pw = /^([0-9]{4})+$/;

      var error_str = "";
      if (this.pwd != this.pwd1) {
        this.pwd = this.pwd1 = "";
        error_str +=
          this.$t("Member_registerCheckPasswordPatternErrMessage") + "</br>";
      }
      if (!re.test(this.uid) || this.uid.length < 4 || this.uid.length > 10) {
        error_str +=
          this.$t("Member_registerAccountPatternErrMessage") + "</br>";
      }
      if (!re.test(this.pwd) || this.pwd.length < 6 || this.pwd.length > 12) {
        error_str +=
          this.$t("Member_registerPasswordPatternErrMessage") + "</br>";
      }
      if (!this.validateEmail(this.email) && this.set_regi.have_email) {
        error_str += this.$t("Member_registerEmailPatternErrMessage") + "</br>";
      }
      if (!this.checkcode()) {
        error_str += this.$t("text_error_cap") + "</br>";
      }
      if (!re_trade_pw.test(this.tradepw) || this.tradepw.length < 4) {
        error_str +=
          this.$t("Member_registerTradePasswordPatternErrMessage") + "</br>";
      }
      if (error_str != null && error_str != "")
        this.$MSG.API_popmsg(error_str, "", "error");
      else {
        this.checkForm();
      }
    },
    checkForm: function () {
      localStorage.setItem("device", this.$Utils.isMobile());
      let param = {
        action: "Register",
        body: {
          uid: this.uid,
          pwd: this.pwd,
          cname: this.cname,
          email: this.email,
          tel: this.phone,
          birthday: this.birthday,
          wechat: "",
          qq: "",
          regdomain: document.location.origin,
          affiliate: localStorage.getItem("agent") ?? "",
          // sms: this.sms,
          ip: localStorage.getItem("ip"),
          device: localStorage.getItem("device"),
          token: "",
          introducer: this.refcode,
          withdrawpw: this.tradepw,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          localStorage.removeItem("introducer");
          Cookies.set("code", res.session_code);
          localStorage.setItem("uid", this.uid);
          localStorage.setItem("utype", 0);
          localStorage.setItem("big_logo", 1);
          this.$store.commit("isreg", false);
          this.$MSG.API_popmsg(
            this.$t("Member_registerSuccessTitle"),
            "regi_success",
            "success"
          );
        } else {
          this.$MSG.API_popmsg(status, "", "error");
        }
      });
    },
    validateEmail(value) {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(value)) {
        return false;
      } else {
        return true;
      }
    },
    checkcode: function () {
      return this.$refs.captgetcode.getdata() == this.captcha ? true : false;
    },
    emailvalselectfun: function (e, val) {
      if (e == "mouse") {
        this.email = this.email.split("@")[0] + val;
        document.getElementById("email_select").style.display = "none";
      } else if (e == "down") {
        if (
          this.emil_index == null ||
          this.emil_index == this.emailselectcompute.length - 1
        ) {
          this.emil_index = 0;
        } else {
          this.emil_index = this.emil_index + 1;
        }
      } else if (e == "up") {
        if (this.emil_index == null || this.emil_index == 0) {
          this.emil_index = this.emailselectcompute.length - 1;
        } else {
          this.emil_index = this.emil_index - 1;
        }
      } else if (e == "key") {
        if (this.email != null && this.email != "") {
          if (this.emil_index != null) {
            this.email =
              this.email.split("@")[0] +
              this.emailselectcompute[this.emil_index];
          }
          document.getElementById("email_select").style.display = "none";
        }
      } else {
        if (this.email != "" && this.email != null) {
          document.getElementById("email_select").style.display = "flex";
        } else {
          document.getElementById("email_select").style.display = "none";
        }
      }
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = "";
          break;
      }
      window.open(url);
    },
    close_reg: function () {
      this.$store.commit("isreg", false);
    },
    open_login: function (id) {
      this.$store.commit(id, true);
    },
  },
  watch: {
    emailselectcompute() {
      this.emil_index = null;
    },
  },
  computed: {
    ...mapGetters(["service"]),
    emailselectcompute() {
      return this.list.filter((v) => {
        if (this.email.includes("@")) {
          return v
            .toLowerCase()
            .includes(this.email.toLowerCase().split("@")[1]);
        } else return v;
      });
    },
  },
  components: { captchaview },
};
</script>
