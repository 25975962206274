<template>
  <div class="new-header header-dividing-border bc3">
    <div>
      <div class="game_serch carousel-live-feed-holder">
        <div class="live-feed-secondary-part-holder">
          <table>
            <thead>
              <tr>
                <th class="text-center">GAME</th>
                <th class="text-center">CODE</th>
                <th class="text-center">TOTAL VOLUME</th>
                <th class="text-center">SHARE/PRICE</th>
                <th class="text-center">MY SHARE</th>
                <th class="text-center">VALUE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in 10" :key="l">
                <td class="text-center"><img src="@/assets/img/game/jili.png" /></td>
                <td class="text-center">JILI</td>
                <td class="text-center">123456</td>
                <td class="text-center">
                  <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                    class="me-1"
                  />
                  <span>123</span>
                  <br />
                  <button class="border-0 text-white bg_green_btn bg-orange p-2 w-100">
                    <span>{{ $t("text_trade") }}</span>
                  </button>
                </td>
                <td class="text-center">123456</td>
                <td class="text-center">
                  <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                    class="me-1"
                  />
                  123
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {},
};
</script>
