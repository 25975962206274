<template>
  <div>
    <swiper
      :autoplay="swiper_options.autoplay"
      :loop="swiper_options.loop"
      :speed="swiper_options.speed"
      :slidesPerView="swiper_options.slidesPerView"
      :spaceBetween="swiper_options.spaceBetween"
      :breakpoints="swiper_options.breakpoints"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="l in list" :key="l">
        <img v-lazy="l" />
      </swiper-slide>
      <!-- <swiper-slide v-for="l in banner_we_mob" :key="l">
        <img v-lazy="require('@/assets/img/banner/' + l + '.png')" />
      </swiper-slide> -->
    </swiper>
  </div>
</template>
<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
export default {
  name: "banner",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return { list: null };
  },
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 15,
      pagination: {
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 1,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  methods: {
    bannerlist: function () {
      let param = {
        action: "banner",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res["urlList"];
      });
    },
  },

  computed: {
    banner_we_mob() {
      // var temp_b =
      //   localStorage.getItem("locale") == null ||
      //   localStorage.getItem("locale") == "" ||
      //   (localStorage.getItem("locale") != "en" &&
      //     localStorage.getItem("locale") != "cn")
      //     ? "en"
      //     : localStorage.getItem("locale");
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
      // if (temp_b == "cn") {
      //   return [
      //     "cn1",
      //     "cn2",
      //     "cn3",
      //     "cn4",
      //     "cn5",
      //     "cn6",
      //     "cn7",
      //     "cn8",
      //     "cn9",
      //     "cn10",
      //   ];
      // } else
      //   return [
      //     "en1",
      //     "en2",
      //     "en3",
      //     "en4",
      //     "en5",
      //     "en6",
      //     "en7",
      //     "en8",
      //     "en9",
      //     "en10",
      //   ];
    },
  },
  mounted() {
    this.bannerlist();
  },
};
</script>
