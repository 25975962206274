<template>
  <div>
    <h1>
      <img
        @click="flag_eye = !flag_eye"
        :src="
          flag_eye
            ? require('@/assets/img/main/open_eye.png')
            : require('@/assets/img/main/close_eye.png')
        "
      /><span
        @click="
          $router.push({
            path: '/ME/wallet',
            query: { id: 'withdraw' },
          })
        "
        >{{ $t("cur") }} {{ flag_eye ? "****" : balance }}</span
      >
      <img
        src="@/assets/img/main/one_click_recycling.png"
        class="ms-2"
        style="width: 20px; height: 20px; cursor: pointer"
        @click="allgameToLobby"
      />
    </h1>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "balance",
  data() {
    return {
      code: Cookies.get("code"),
      flag_eye: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["balance"]),
  },
  methods: {
    ...mapActions(["getBalance"]),
    allgameToLobby: function () {
      let param = {
        action: "allgame_to_LOBBY",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.$MSG.API_popmsg(res["balance"], "allgameTolobby", "success");
      });
    },
  },
  created() {
    if (this.code != null) {
      this.getBalance();
    }
  },
  watch: {},
};
</script>
