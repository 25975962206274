<template>
  <div class="finance_page">
    <div class="finance_card">
      <div class="finance_close" @click="this.$emit('fun_pw_page', false)">
        ×
      </div>
      <div
        class="finance_title"
        :class="action.toLowerCase() == 'sell' ? 'bg-red' : ''"
      >
        {{ $t("text_input_pw") }}
      </div>

      <div class="finance_main">
        <div class="finance_deposit" style="margin-top: 10px">
          <div>
            <p class="mb-2">
              {{ $t("text_input_pw") }}
            </p>
            <input
              type="text"
              class="finance_text"
              v-model="withpw"
              minlength="4"
              maxlength="4"
            />
            <p v-show="flag_pay_error">
              <span style="color: Red">{{ $t("error_tips") }}</span>
            </p>
          </div>

          <div class="col-10 text-white mx-auto my-3">
            <button
              @click="fun_fina_inve"
              class="border-0 text-white p-2 w-100"
              :disabled="flag_pay_error"
              :style="
                flag_pay_error ? 'cursor: no-drop;background-color: #CCC;' : ''
              "
              :class="
                action.toLowerCase() == 'sell'
                  ? 'bg_red_btn'
                  : 'bg_light_green_btn'
              "
            >
              <span>{{
                action.toLowerCase() == "sell"
                  ? $t("text_sell")
                  : $t("invest_text6")
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: {
    click_list: Array,
    payment: String,
    action: String,
    amount: String,
    fina_inve: String,
  },
  data() {
    return {
      withpw: "",
    };
  },
  methods: {
    fun_fina_inve: function () {
      try {
        if (this.fina_inve.toLowerCase() == "etf") this.buy_etf_item();
        else if (this.fina_inve.toLowerCase() == "stock")
          this.buy_sharebonus_item();
        else this.$MSG.API_popmsg("ERROR", "", "error");
      } catch {
        this.$MSG.API_popmsg("ERROR", "", "error");
      }
    },
    buy_etf_item: function () {
      if (this.withpw != "" && this.withpw != null) {
        let param = {
          action: this.action.toLowerCase() == "sell" ? "etf_sale" : "etf_buy",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            mpkidx: this.click_list.mpkidx,
            finance: this.payment,
            withdrawpw: this.withpw,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            this.close_fun();
            this.$MSG.API_popmsg(res.msg, "", "success");
          }
        });
      } else this.flag_pay_error = true;
    },

    buy_sharebonus_item: function () {
      if (this.withpw != "" && this.withpw != null) {
        let param = {
          action:
            this.action.toLowerCase() == "sell"
              ? "betstock_sale"
              : "betstock_buy",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            mpkidx: this.click_list.mpkidx,
            payment: this.payment,
            unit: this.amount,
            withdrawpw: this.withpw,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            this.close_fun();
            this.$MSG.API_popmsg(res.msg, "", "success");
          } else {
            this.$MSG.API_popmsg(res.msg, "", "error");
          }
        });
      } else this.flag_pay_error = true;
    },

    close_fun: function () {
      this.$emit("fun_pw_page", false, "fun_flag_page");
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>
