<template>
  <div>
    <div class="row mt-3">
      <div class="d-flex flex-lg-row flex-column">
        <div class="s_detail_top_left s_detail_r">
          <div class="d-flex flex-column justify-content-center">
            <div class="d-flex flex-column">
              <div class="s_detail_top_left_num_title">$100.58</div>
              <div class="s_detail_top_left_num_subtitle d-flex">
                <div class="price-change-arrow"></div>
                <div class="price-change-abs">-5.55</div>
                <div class="price-change-pct">-1.2%</div>
              </div>
            </div>
            <div class="s_detail_top_left_date">2024/7/17 00:00:00</div>
          </div>
        </div>
        <div class="s_detail_top_right w-100 position-relative d-block">
          <div>
            <div class="s_detail_top_right_title mb-0">
              123456789123456798(8888)
            </div>
            <div class="s_detail_top_right_subtitle d-flex flex-wrap">
              <div
                class="s_detail_top_right_bid d-flex c-pointer align-items-center"
              >
                <label class="bid-label">Bid:</label>
                <span class="bid-info">$487.68 X 281</span>
              </div>
              <div
                class="s_detail_top_right_ask d-flex c-pointer align-items-center"
              >
                <label class="bid-label">Ask:</label>
                <span class="bid-info">$487.68 X 281</span>
              </div>
              <div
                class="s_detail_top_right_volumn d-flex c-pointer align-items-center"
              >
                <label class="bid-label">Volumn:</label>
                <span class="bid-info">9,478,877</span>
              </div>
              <div class="s_detail_top_right_hl d-flex c-pointer">
                <label class="bid-label">High/Low:</label>
                <div class="d-flex flex-column me-0">
                  <span class="bid-info">485.79 - 503.94</span>
                  <!-- <span class="d-flex"
                    ><input
                      type="range"
                      min="485.79"
                      max="503.94"
                      step="0.01"
                      value="487.08"
                      style="
                        background: linear-gradient(
                          to right,
                          #dd1d28 0%,
                          #dd1d28 7.107438016528735%,
                          #00eb80 7.107438016528735%,
                          #00eb80 100%
                        );
                      "
                  /></span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column mt-3">
        <h1 class="text-wrap text-break">123456789123456798(8888)</h1>
        <charview :tableData="tableData[0]" />
      </div>

      <div class="d-flex flex-lg-row flex-column mt-3 div_key">
        <div class="d-flex flex-column col-lg-6 col-12">
          <h2 class="div_key-h2">Key Data</h2>
          <div>
            <div class="key_table w100">
              <div class="key_tablebody w-100 h-100 d-table">
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Sector</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    online casino
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Market of Operation</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    全球
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Todays’High/Low</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    當日的最高與最低點
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Share Volume</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    成交量（成交金額總和
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Previous Close</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    24小時前的最終價格
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Market Cap</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    市值 （總發行股票x當前股價）
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Special Dividends</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    每股分配盈餘
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">
                    Special Dividend Pay Date
                  </div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    發放分配盈餘的日期
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Total Deposit Player</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    8888
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Total Wagering Amount</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    888888
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column col-lg-6 col-12 mt-lg-0 mt-4">
          <h2 class="div_key-h2">OPERATION DATA</h2>
          <div>
            <div class="key_table w100 opera_table">
              <div class="key_tablebody w-100 h-100 d-table">
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold" style="color: green">
                    This Month
                  </div>
                  <div
                    class="key_tablecell key_tablecell-right text-end"
                    style="color: #dd1d28"
                  >
                    Last Month
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Monthly Register User</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    Monthly Register User
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Monthly Deposit User</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    Monthly Deposit User
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">
                    Monthly Wagering Amount
                  </div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    Monthly Wagering Amount
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Monthly Betting Slip</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    Monthly Betting Slip
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">
                    Monthly Average Daily User
                  </div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    Monthly Average Daily User
                  </div>
                </div>
                <div class="key_tablerow">
                  <div class="key_tablecell fw-bold">Monthly Game Revenue</div>
                  <div class="key_tablecell key_tablecell-right text-end">
                    Monthly Game Revenue
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import charview from "./char.vue";
export default {
  props: {},
  data() {
    return {
      tableData: [
        [
          236, 500, 450, 800, 760, 210, 198, 60, 55, 80, 334, 250, 336, 503,
          290,
        ],
        [1320, 532, 901, 334, 1290, 730, 820],
        [60, 55, 80, 334, 250, 336, 503],
        [107, 99, 63, 28, 71, 106, 160],
        [88, 33, 62, 98, 107, 116, 138],
        [60, 55, 80, 334, 250, 336, 503],
        [107, 99, 63, 28, 71, 106, 160],
        [138, 33, 62, 98, 107, 116, 88],
        [300, 360, 350, 295, 286, 210, 290],
      ],
    };
  },
  components: { charview },
  methods: {},
  mounted() {},
  created() {},
  watch: {},
};
</script>
