<template>
  <div id="mobbottom" class="d-xl-none d-flex">
    <div class="mob_footer" style="box-shadow: 0px -2px 3px #eee">
      <div class="footer_card" @click="show_nav()">
        <div class="footer_icon">
          <img src="@/assets/img/main/ic1-1.png" alt="" />
        </div>
      </div>
      <div class="footer_card" @click="this.$router.push('/BO/invite')">
        <div class="footer_icon">
          <img src="@/assets/img/left/share.png" alt="" style="width: 20px" />
        </div>
      </div>
      <div class="footer_card" @click="this.$router.push('/ME/wallet')">
        <div class="footer_icon">
          <img src="@/assets/img/main/diamond.png" alt="" />
        </div>
      </div>
      <div class="footer_card" @click="this.$router.push('/ME/assetscenter')">
        <div class="footer_icon">
          <img src="@/assets/img/main/ic4.png" alt="" />
        </div>
      </div>
      <div class="footer_card" @click="this.$router.push('/')">
        <div class="footer_icon">
          <img src="@/assets/img/main/ic5.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["flag_nav"]),
  },
  methods: {
    show_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
  },
};
</script>
