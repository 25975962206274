<template>
  <div class="wc-WebConsoleModule_SiteContainer">
    <div class="wc-PageView" style="height: 100%">
      <div class="wc-PageView_Main wc-MembersPageResponsive_MainContainer">
        <div class="wcl-CommonElementStyle_NavContentContainer">
          <div class="mim-MembersIframeModule">
            <div class="wc-WebConsoleModule">
              <div
                class="wc-WebConsoleModule_Content wc-WebConsoleModule_Content-footer-displayed"
              >
                <div
                  class="wc-PageView wc-PageView-wide wc-PageView-1400 wc-PageView-nonavmenu"
                  style="height: 100vh"
                >
                  <div
                    class="wc-PageView_ContentContainer wc-OpenAccountLegacyPage_ContentContainer"
                  >
                    <div class="mt-xl-5 mt-4">
                      <div class="p-3">
                        <div class="mb-3">
                          <div class="charg_main justify-content-between">
                            <div>{{ $t("main_acc") }}</div>
                            <div class="charge_text">{{ balance }}</div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="mb-1">{{ $t("charge_text2") }}</div>
                          <input type="text" />
                        </div>
                        <div class="mb-3">
                          <div class="mb-1">{{ $t("charge_text3") }}</div>
                          <input type="text" />
                        </div>
                        <div class="mb-3">
                          <div class="mb-1">{{ $t("charge_text4") }}</div>
                          <input type="text" />
                        </div>
                        <div class="text-center">
                          <button
                            id="submit"
                            type="button"
                            class="border-0 text-white bg_green_btn p-2 w-50 mt-3"
                          >
                            {{ $t("shared_comfirm").toUpperCase() }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["balance"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getBalance"]),
    getBalance1: function () {
      let param = {
        action: "game_to_LOBBY",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param);

      this.getBalance();
    },
  },
  created() {
    this.getBalance1();
  },
};
</script>
