<template>
  <div>
    <div class="mobile-menu-containers mt-3">
      <a
        class="lower"
        @click="this.$router.push('/'), false_nav()"
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_trade.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("text_home") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a>
      <!-- <a
        class="lower"
        @click="this.$router.push(''), false_nav()"
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_about.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("text_about") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a> -->
      <a
        class="lower"
        @click="this.$router.push('/help/F'), false_nav()"
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_qa.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("text_qa") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a>
      <!-- <a
        class="lower"
        @click="
          this.$router.push('/VIP_intro');
          false_nav();
        "
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_vip.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("vip_introduce_text10") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a> -->
      <a
        class="lower"
        @click="this.$router.push('/BO/invite'), false_nav()"
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_share.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("text_ste") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a>
      <a
        class="lower"
        @click="this.$router.push('/ME/assetscenter'), false_nav()"
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_asset.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("text_assets_center") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a>
      <a
        class="lower"
        @click="this.$store.commit('isann', true), false_nav()"
        style="cursor: pointer"
      >
        <img
          src="@/assets/img/left/side_ic_notice.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("notice") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a>
      <a
        class="lower"
        @click="
          this.$router.push('/promo');
          false_nav();
        "
        style="cursor: pointer"
        ><img
          src="@/assets/img/left/side_ic_promo.png"
          alt="Menu Icon"
          class="menu-icon"
        />
        <div class="mobile-menu-containers-main-titles">
          <h4>{{ $t("faq_5") }}</h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="6"
          class="arrow-svg"
          viewBox="0 0 3 6"
        >
          <path
            fill="#727D8F"
            fill-rule="nonzero"
            stroke="#727D8F"
            stroke-width=".3"
            d="M1.935 3L0 .795C-.206.586-.196.223.009.01.22-.21.58-.205.762.03l2.25 2.59c.082.097.133.24.138.381a.568.568 0 0 1-.138.379L.768 5.963c-.183.241-.547.248-.759.028-.205-.213-.215-.577-.016-.78L1.935 3z"
          ></path>
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: { subid: String },
  computed: {
    ...mapGetters(["service"]),
    Id: function () {
      if (this.$route.path != null) return this.$route.path;
      else return "";
    },
  },
  methods: {
    ...mapActions(["getService"]),
    false_nav: function () {
      this.$store.commit("flagnav", false);
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = this.$store.state.service.qq[e];
          break;
      }
      window.open(url);
    },
  },
  created() {
    this.getService();
  },
};
</script>
